import Agent from "../../../Interfaces/agent";
import { Deal } from "../../../Interfaces/deal";
import { FieldTypes } from "../../../utils/fieldTypes";

const ClosedMPDefaultFields = (agent: Agent, deal: Deal) => {
  const fields = [
    {
      label: `This survey is broken into three sections asking about your experience with your agent, your loan officer, and your overall experience purchasing through Clever and ${
        deal.loan_officer.company_name || "the Mortgage Partner"
      }. <br/><br/>For all scale questions, please consider 1 the lowest rating`,
      keyName: "intro",
      type: FieldTypes.paragraph,
    },
    // AGENT
    {
      label: `${agent.firstname} ${agent.lastname} of ${agent.brokerage_name}`,
      keyName: "agent_brokerage",
      type: FieldTypes.header,
    },
    {
      label: `On a scale of 1-5, please rate ${agent.firstname} on each of the following items:`,
      keyName: "scale_1_5",
      type: FieldTypes.paragraph,
    },
    {
      label: `Communication, Responsiveness, and Availability`,
      keyName: `agent_rating__communication__responsiveness__and_availability`,
      type: FieldTypes.radioNumber,
      required: true,
      options: ["1", "2", "3", "4", "5"],
    },
    {
      label: `Knowledge of Local Area`,
      keyName: `agent_rating__knowledge_of_local_area`,
      type: FieldTypes.radioNumber,
      required: true,
      options: ["1", "2", "3", "4", "5"],
    },
    {
      label: `Negotiations/Paperwork`,
      keyName: `agent_rating__negotiations_paperwork`,
      type: FieldTypes.radioNumber,
      required: true,
      options: ["1", "2", "3", "4", "5"],
    },
    {
      label: `Overall Rating`,
      keyName: `agent_rating__overall_rating`,
      type: FieldTypes.radioNumber,
      required: true,
      options: ["1", "2", "3", "4", "5"],
    },
    {
      label: `What went well with your agent? What went poorly with your agent?`,
      keyName: `what_went_well_with_your_agent__what_went_poorly_with_your_agent___mp_`,
      type: FieldTypes.textarea,
    },
    // END AGENT
    // LOAN OFFICER
    {
      label: `${deal.loan_officer.firstname} ${deal.loan_officer.lastname} of ${
        deal.loan_officer.company_name || "the Mortgage Partner"
      }`,
      keyName: "loan_officer_header",
      type: FieldTypes.header,
    },
    {
      label: `How satisfied are you with the loan you received from ${
        deal.loan_officer.company_name || "the Mortgage Partner"
      }?`,
      keyName: `lo_rating__how_satisfied_are_you_with_the_loan_you_received_from_lender_`,
      type: FieldTypes.radioNumber,
      options: ["1", "2", "3", "4", "5"],
      required: true,
    },
    {
      label: `Communication, Responsiveness, and Availability`,
      keyName: `lo_rating__communication__responsiveness__and_availability`,
      type: FieldTypes.radioNumber,
      options: ["1", "2", "3", "4", "5"],
      required: true,
    },
    {
      label: `Expertise/Advice`,
      keyName: `lo_rating__expertise_advice`,
      type: FieldTypes.radioNumber,
      options: ["1", "2", "3", "4", "5"],
      required: true,
    },
    {
      label: `Overall Rating`,
      keyName: `lo_rating__overall_rating`,
      type: FieldTypes.radioNumber,
      options: ["1", "2", "3", "4", "5"],
      required: true,
    },
    {
      label: `How did ${deal.loan_officer.firstname} fall short of your expectations?`,
      keyName: `how_did_loan_officer_fall_short_of_your_expectations_`,
      type: FieldTypes.textarea,
      triggers: {
        lo_rating__overall_rating: ["4", "3", "2", "1"],
      },
    },
    // END LOAN OFFICER
    // CLEVER REAL ESTATE
    {
      label: `On a scale of 1-5, how much do you agree with the following statement:`,
      type: FieldTypes.paragraph,
      keyName: "following_statement",
    },
    {
      label: `The partnership between ${deal.loan_officer.company_name} and Clever created a seamless buying experience`,
      keyName: `the_partnership_between_lender_and_clever_created_a_seamless_buying_experience`,
      type: FieldTypes.radioNumber,
      options: ["1", "2", "3", "4", "5"],
      required: true,
    },
    {
      label: `How likely are you to recommend the Clever and ${deal.loan_officer.company_name} partnership program to a friend?`,
      keyName: `how_likely_are_you_to_recommend_the_lender_partnership_program_to_a_friend_`,
      type: FieldTypes.radioNumber,
      options: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      required: true,
    },
    {
      label: `Where did we fall short of your expectations?`,
      keyName: `where_did_we_fall_short_of_your_expectations___mp_`,
      type: FieldTypes.textarea,
      triggers: {
        how_likely_are_you_to_recommend_the_lender_partnership_program_to_a_friend_:
          ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
      },
    },
  ];

  return fields;
};

const getFields = (deal: Deal, agent: Agent) => {
  const fields = ClosedMPDefaultFields(agent, deal);
  return fields;
};

export { getFields };
