import React from "react";
import AuthenticationLayout from "../components/AuthenticationLayout";
import ClosedMPContainer from "../components/FormBuilder/ClosedMPSurvey/ClosedMPSurvey";
import "../styles/form.scss";

const Closed = () => (
  <AuthenticationLayout>
    <ClosedMPContainer />
  </AuthenticationLayout>
);

export default Closed;
